<template>
  <div class="mod-config">
    <el-form :inline="true" :model="dataForm" ref="DataForm">
      <el-form-item prop="did">
        <el-select
          v-model="dataForm.did"
          clearable
          placeholder="按部门筛选"
          @visible-change="departmentSelect"
          @change="departmentSelectChange"
          name="departmen"
        >
          <el-option
            v-for="item in departmentOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="tid">
        <el-select
          v-model="dataForm.tid"
          clearable
          placeholder="按班组筛选"
          @visible-change="teamSelect"
          @change="teamSelectChange"
        >
          <el-option
            v-for="item in teamOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="eid">
        <el-select
          v-model="dataForm.eid"
          clearable
          filterable
          placeholder="按员工筛选"
          @visible-change="employeeSelect"
          @change="employeeSelectChange"
        >
          <el-option
            v-for="item in employeeOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="carId">
        <el-select
          v-model="dataForm.carId"
          clearable
          placeholder="按车辆筛选"
          @visible-change="carSelect"
        >
          <el-option
            v-for="item in carOptions"
            :key="item.id"
            :label="item.number"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <!-- <el-form-item prop="type">
        <el-select
          v-model="dataForm.type"
          clearable
          placeholder="按类型筛选"
          @visible-change="typeSelect"
        >
          <el-option
            v-for="item in typeOptions"
            :key="item.id"
            :label="item.label"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item> -->
      <el-form-item prop="status">
        <el-select v-model="dataForm.status" clearable placeholder="按状态筛选">
          <el-option
            v-for="item in patrol_statusOpt"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button @click="getDataList()">查询</el-button>
        <!-- <el-button type="primary" @click="addOrUpdateHandle()">新增</el-button> -->
      </el-form-item>
    </el-form>

    <el-table
      :data="dataList"
      border
      size="medium"
      v-loading="dataListLoading"
      style="width: 100%"
    >
      <el-table-column
        type="index"
        width="80"
        :index="
          (e) => {
            return (e + 1) * pageIndex;
          }
        "
        align="center"
        label="序号"
        fixed="left"
      >
      </el-table-column>
      <el-table-column
        prop="dname"
        header-align="center"
        align="center"
        label="部门"
      >
      </el-table-column>
      <el-table-column
        prop="tname"
        header-align="center"
        align="center"
        label="班组"
      >
      </el-table-column>
      <el-table-column
        prop="carNumber"
        header-align="center"
        align="center"
        label="车牌号"
      >
      </el-table-column>
      <el-table-column
        prop="ename"
        header-align="center"
        align="center"
        label="检查人"
      >
      </el-table-column>

      <el-table-column
        prop="dailyCheckType"
        header-align="center"
        align="center"
        label="日检类型"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.dailyCheckType == 0">出车检查</span>
          <span v-if="scope.row.dailyCheckType == 1">收车检查</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="carType"
        header-align="center"
        align="center"
        label="车辆类型"
      >
        <!-- <template slot-scope="scope">
          <span v-if="scope.row.carType == 0">燃油车</span>
          <span v-if="scope.row.carType == 1">电动车</span>
        </template> -->
      </el-table-column>
      <el-table-column
        prop="checkDate"
        header-align="center"
        align="center"
        label="检查时间"
      >
      </el-table-column>
      <el-table-column prop="status" align="center" label="状态">
        <template slot-scope="scope">
          <span>{{ patrol_statusOpt[scope.row.status].label }}</span>
        </template>
      </el-table-column>
      <el-table-column
        fixed="right"
        header-align="center"
        align="center"
        width="150"
        label="操作"
      >
        <template slot-scope="scope">
          <el-button
            type="primary"
            size="mini"
            @click="addOrUpdateHandle(scope.row.id, 1)"
            >查看</el-button
          >
          <el-button
            v-if="scope.row.status == 0"
            type="primary"
            size="mini"
            @click="addOrUpdateHandle(scope.row.id)"
            >修改</el-button
          >
          <el-button
            v-if="scope.row.status == 1"
            type="primary"
            size="mini"
            @click="showExamine(scope.row.id)"
            >审核</el-button
          >
          <!-- <el-button
            type="danger"
            size="mini"
            @click="deleteHandle(scope.row.id)"
            >删除</el-button
          > -->
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      style="text-align: center; margin-top: 10px"
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100, 1000, 10000]"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper"
    >
    </el-pagination>
    <!-- 弹窗, 新增 / 修改 -->
    <add-or-update
      v-if="addOrUpdateVisible"
      ref="addOrUpdate"
      @refreshDataList="getDataList"
    ></add-or-update>

    <el-dialog title="审核" :visible.sync="examineVisible" width="30%">
      <el-input v-model="remarks" placeholder="请输入您的审核意见"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="
            examineVisible = false;
            examine(0);
          "
          >拒绝</el-button
        >
        <el-button
          type="primary"
          @click="
            examineVisible = false;
            examine(1);
          "
          >通过</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import AddOrUpdate from "./carcheck-add-or-update";

export default {
  data() {
    return {
      // 巡查职守状态列表
      patrol_statusOpt: [
        { label: "已派发例检任务", value: 0 },
        { label: "已填写情况", value: 1 },
        { label: "审核不合格，重新填写", value: 2 },
        { label: "已归档入库", value: 3 },
      ],
      examineVisible: false,

      remarks: "",
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dataListLoading: false,
      dataListSelections: [],
      addOrUpdateVisible: false,

      dataForm: {
        did: "",
        tid: "",
        eid: "",
        carId: "",
        type: "",
        status: "",
      },

      departmentOptions: [], //部门列表选项
      teamOptions: [], // 班组列表选项
      employeeOptions: [], //员工列表选项
      carOptions: [], //车辆列表选项
      typeOptions: [], //类型列表选项
    };
  },
  components: {
    AddOrUpdate,
  },
  created() {
    this.getDataList();
  },
  methods: {
    // 部门选择(打开下拉框)
    departmentSelect(e) {
      if (e) {
        this.$http.department.list({ limit: 99999, page: 1 }).then((res) => {
          this.departmentOptions = res?.data?.list;
        });
      }
    },
    // 部门选择(选择发生变化)
    departmentSelectChange(e) {
      this.dataForm.tid = "";
      this.teamOptions = [];
      this.dataForm.eid = "";
      this.employeeOptions = [];
      this.dataForm.carId = "";
      this.carOptions = [];
    },
    // 班组选择(打开下拉框)
    teamSelect(e) {
      if (e) {
        this.$http.team
          .list({ did: this.dataForm.did, limit: 99999, page: 1 })
          .then((res) => {
            this.teamOptions = res?.data?.list;
          });
      }
    },
    // 班组选择(选择发生变化)
    teamSelectChange(e) {
      this.dataForm.eid = "";
      this.employeeOptions = [];
    },
    // 员工选择(打开下拉框)
    employeeSelect(e) {
      if (e) {
        this.$http.employee
          .list({
            did: this.dataForm.did,
            tid: this.dataForm.tid,
            limit: 99999,
            page: 1,
          })
          .then((res) => {
            this.employeeOptions = res?.data?.list;
          });
      }
    },
    // 员工选择(选择发生变化)
    employeeSelectChange(e) {
      this.dataForm.carId = "";
      this.carOptions = [];
    },
    // 车辆选择(打开下拉框)
    carSelect(e) {
      if (e) {
        this.$http.car
          .list({
            did: this.dataForm.did,
            tid: this.dataForm.tid,
            eid: this.dataForm.eid,
            limit: 99999,
            page: 1,
          })
          .then((res) => {
            this.carOptions = res?.data?.list;
          });
      }
    },

    examine(flag) {
      this.$http.carcheck
        .archive({ id: this.id, flag, content: this.remarks })
        .then((data) => {
          if (data && data.code === 0) {
            this.$message.success(data.msg);
            this.getDataList();
          }
        });
    },
    showExamine(id) {
      this.id = id;
      this.examineVisible = true;
    },
    // 获取数据列表
    getDataList() {
      this.dataListLoading = true;
      this.dataForm.page = this.pageIndex;
      this.dataForm.limit = this.pageSize;
      this.dataForm.type = 0;

      this.$http.carcheck.list(this.dataForm).then((data) => {
        if (data && data.code === 0) {
          this.dataList = data.data.list;
          this.totalPage = data.data.totalCount;
        } else {
          this.dataList = [];
          this.totalPage = 0;
        }
        this.dataListLoading = false;
      });
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageIndex = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val;
      this.getDataList();
    },
    // 新增 / 修改
    addOrUpdateHandle(id, flag) {
      this.addOrUpdateVisible = true;
      this.$nextTick(() => {
        this.$refs.addOrUpdate.init(id, flag);
      });
    },
    // 删除
    deleteHandle(id) {
      this.$confirm(`确定要进行删除吗？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$http.carcheck
          .delete({
            id,
          })
          .then((data) => {
            if (data && data.code === 0) {
              this.$message({
                message: data.msg,
                type: "success",
                duration: 1500,
                onClose: () => {
                  this.getDataList();
                },
              });
            } else {
              this.$message.error(data.msg);
            }
          });
      });
    },
  },
};
</script>
